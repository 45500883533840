import dayjs, { Dayjs } from "dayjs";
import { GuruHoldings } from "./types";
import { User } from "firebase/auth";

export const bubbleChartBackgroundColors = [
  "#6b5ace",
  "#bf7ccb",
  "#4433e1",
  "#d3c9f7",
  "#cf0c9b",
  "#858fe5",
  "#320b7a",
  "#593a94",
  "#684a9f",
  "#876db7",
  "#4433e1",
  "#bf7ccb",
  "#d36c27",
];

export const doughnutChartBackgroundColors = [
  "#c0ade3",
  "#ad96d8",
  "#9a82c5",
  "#876db7",
  "#765aab",
  "#684a9f",
  "#593a94",
  "#4c2c88",
  "#462289",
  "#3c1488",
  "#320b7a",
  "#290272",
  "#21015d",
  "#180144",
  "#11032d",
  "#d3c9f7",
  "#6b5ace",
  "#4433e1",
  "#858fe5",
  "#bf7ccb",
];

// TODO: Format based on locale
// TODO: Replace "-" with minus HTML character for negative numbers
// TODO: Reconsider how to handle broken cases
/**
 * Format number as string according to 10kreader.com brand
 * Format based off either number of decimals or significant digits
 * @param number money number to be formatted
 * Number is a normal number e.g., generally dollars, shares outstanding, etc.
 * @param decimals number of decimal points in format
 * - default number of decimals is 2
 * - if number (on the order of millions, thousands) has .00 in its decimal
 * places, this is likely due to rounding, so we prevent against that.
 * i.e., no numbers displayed like 258.00K or 526.00MM as this reports
 * a false level of precision
 * @returns number formatted as string
 * if number is not a number, returns -1
 * if an invalid type is given, returns -1
 */
export const formatNumber = (
  number: number | null,
  digits: number = 2,
  type: string = "decimals",
  integer: boolean = true
) => {
  if (!number) return "0"; // assume it is 0

  let newNumber: string;
  const roundedNumber = parseFloat(number.toFixed(0));
  const orderOfMagnitude = parseFloat(Math.abs(number).toFixed(0)).toFixed(
    0
  ).length;

  if (type == "decimals") {
    if (orderOfMagnitude < 4) {
      newNumber = integer
        ? `${roundedNumber.toFixed(0)}`
        : `${roundedNumber.toFixed(digits)}`;
    } else if (orderOfMagnitude < 7) {
      digits =
        roundedNumber / 1e3 == Math.floor(roundedNumber / 1e3) ? 0 : digits;
      newNumber = `${(roundedNumber / 1e3).toFixed(digits)}K`;
    } else if (orderOfMagnitude < 10) {
      digits =
        roundedNumber / 1e6 == Math.floor(roundedNumber / 1e6) ? 0 : digits;
      newNumber = `${(roundedNumber / 1e6).toFixed(digits)}M`;
    } else if (orderOfMagnitude < 13) {
      newNumber = `${(roundedNumber / 1e9).toFixed(digits)}B`;
    } else if (orderOfMagnitude < 16) {
      newNumber = `${(roundedNumber / 1e12).toFixed(digits)}T`;
    } /*if (orderOfMagnitude >= 16)*/ else {
      newNumber = `${(roundedNumber / 1e15).toFixed(digits)}Q`;
    }
  } else if (type == "digits") {
    if (orderOfMagnitude < 4) {
      newNumber = integer
        ? `${roundedNumber.toFixed(0)}`
        : `${roundedNumber.toPrecision(digits)}`;
    } else if (orderOfMagnitude < 7) {
      newNumber = `${(roundedNumber / 1e3).toPrecision(digits)}K`;
    } else if (orderOfMagnitude < 10) {
      newNumber = `${(roundedNumber / 1e6).toPrecision(digits)}M`;
    } else if (orderOfMagnitude < 13) {
      newNumber = `${(roundedNumber / 1e9).toPrecision(digits)}B`;
    } else if (orderOfMagnitude < 16) {
      newNumber = `${(roundedNumber / 1e12).toPrecision(digits)}T`;
    } /*if (orderOfMagnitude >= 16)*/ else {
      newNumber = `${(roundedNumber / 1e15).toPrecision(digits)}Q`;
    }
  } else {
    return "-2";
  }
  return newNumber;
};

/**
 * Format percent as string according to 10kreader.com brand
 * Set percent to either have specified number of decimals or 1 sig fig
 * @param number percent to be formatted
 * @param decimals number of decimals, in case of >1 sig fig by default
 * @returns number formatted as string
 * - if number is not a number, return -1
 */
export const formatPercent = (number: number | null, decimals: number = 1) => {
  if (!number) return "0%";
  const numberInPercent = number * 100;
  if (
    numberInPercent < 1 &&
    numberInPercent.toPrecision(1).length >
      numberInPercent.toFixed(decimals).length
  )
    return `${numberInPercent.toPrecision(1)}%`;
  return `${numberInPercent.toFixed(decimals)}%`;
};

export const formatHoldingsDate = (dateString: string, spacing?: boolean) => {
  const date = dayjs(dateString);
  const month = date.month();
  let quarter = "";

  if (month >= 0 && month <= 2) {
    quarter = "q1";
  } else if (month >= 3 && month <= 5) {
    quarter = "q2";
  } else if (month >= 6 && month <= 8) {
    quarter = "q3";
  } else if (month >= 9 && month <= 11) {
    quarter = "q4";
  }

  let year = date.year();

  if (spacing) {
    return `${year} ${quarter}`;
  } else {
    return `${year}${quarter}`;
  }
};

// Function takes an all caps string and returns a capitalized string
export const capitalizeString = (string: string | undefined) => {
  if (typeof string === undefined || string?.length === 0) return "";
  const arrayOfStrings = string?.toLowerCase().split(" ");
  const capitalizedString = arrayOfStrings
    ?.map((string) => {
      if (string.length > 0) {
        return `${string[0].toUpperCase()}${string.substring(
          1,
          string.length
        )}`;
      } else {
        return "";
      }
    })
    .join(" ");
  return capitalizedString;
};

export const formatTranscriptDate = (date: String, short: boolean) => {
  const year = date.substring(0, 4);
  const quarter = date.substring(4, 6).toUpperCase();

  if (short) {
    return `${quarter} '${year.slice(2, 4)}`;
  } else {
    return `${quarter} ${year}`;
  }
};

export function saveText(text: string, filename: string) {
  var a = document.createElement("a");
  a.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  a.setAttribute("download", filename);
  a.click();
}

export const formatMetric = (
  metric: number | null,
  type: string,
  graph: boolean
) => {
  let formattedMetric: string | null;

  switch (type) {
    case "%":
      if (graph) {
        formattedMetric = metric ? `${metric.toFixed(1)}%` : null;
      } else {
        formattedMetric = metric ? `${(metric * 100).toFixed(2)}%` : null;
      }
      break;
    case "days":
      formattedMetric = metric ? `${metric.toFixed(0)} days` : null;
      break;
    case "$":
      formattedMetric = metric ? `$${metric.toFixed(2)}` : null;
      break;
    case "times":
      formattedMetric = metric ? `${metric.toFixed(2)}x` : null;
      break;
  }

  return formattedMetric;
};

// Check if document exists and upload details if not
export const checkUserDetails = async (user: User) => {
  // Reference user docs
  const { doc, getDoc, setDoc } = await import("firebase/firestore");
  const { db } = await import("./firebase");
  const userRef = doc(db, "users", `${user?.uid}`);
  const emailRef = doc(db, "usersEmail", `${user?.email}`);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) return;

  await setDoc(userRef, {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
  });

  await setDoc(emailRef, {
    uid: user.uid,
  });
};

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

export const formatValue = (
  metric: number | null,
  type: string,
  multiplier: number
) => {
  if (!metric) return "";
  switch (type) {
    case "number":
      return numberFormatter.format(metric / multiplier);
    case "ratioFormat":
      return numberFormatter.format(metric / multiplier);
    case "%":
      return `${(metric * 100).toFixed(2)}%`;
    case "normal":
      return metric.toFixed(2);
    case "ratio":
      return metric.toFixed(2);
  }
};

export const processGuruTrades = (holdings: GuruHoldings[][]) => {
  if (!holdings || holdings.length === 0) return { data: [] };

  const all_transactions = [];

  for (let i = 0; i < holdings.length; i++) {
    const transactions = [];
    const currentQ = holdings[i];
    const previousQ = holdings[i - 1];

    if (previousQ) {
      const uniqueInvestors = [];
      for (const owner of currentQ) {
        if (!uniqueInvestors.find((i) => i.cik === owner.cik)) {
          uniqueInvestors.push({
            cik: owner.cik,
            name: owner.name,
            owner: owner.owner,
          });
        }
      }
      for (const owner of previousQ) {
        if (!uniqueInvestors.find((i) => i.cik === owner.cik)) {
          uniqueInvestors.push({
            cik: owner.cik,
            name: owner.name,
            owner: owner.owner,
          });
        }
      }

      for (const { cik, name, owner } of uniqueInvestors) {
        const currentQOwner = currentQ.find((i) => i.cik === cik);
        const previousQOwner = previousQ.find((i) => i.cik === cik);
        if (currentQOwner && previousQOwner) {
          const currentQOwnerShares = currentQOwner.shares;
          const previousQOwnerShares = previousQOwner.shares;
          const currentQOwnerWeighting = currentQOwner.percentage;
          const previousQOwnerWeighting = previousQOwner.percentage;
          const sharesDiff = currentQOwnerShares - previousQOwnerShares;
          const weightingDiff =
            currentQOwnerWeighting - previousQOwnerWeighting;

          let status = "";

          if (sharesDiff === 0) {
            status = "Nothing";
          } else if (sharesDiff > 0 && currentQOwner && previousQOwner) {
            status = `${((sharesDiff / previousQOwnerShares) * 100).toFixed(
              2
            )}%`;
          } else if (sharesDiff < 0 && currentQOwner && previousQOwner) {
            status = `${((sharesDiff / previousQOwnerShares) * 100).toFixed(
              2
            )}%`;
          }

          transactions.push({
            cik,
            name,
            owner: `${owner}|${cik}`,
            date: currentQOwner.date,
            shares: currentQOwner.shares,
            value: currentQOwner.value,
            weighting: `${currentQOwner.percentage.toFixed(2)}%`,
            sharesDiff,
            status,
            weightingDiff,
          });
        } else if (currentQOwner) {
          const currentQOwnerShares = currentQOwner.shares;
          const currentQOwnerWeighting = currentQOwner.percentage;
          transactions.push({
            cik,
            name,
            owner: `${owner}|${cik}`,
            date: currentQOwner.date,
            shares: currentQOwner.shares,
            value: currentQOwner.value,
            weighting: `${currentQOwner.percentage.toFixed(2)}%`,
            status: "New",
            sharesDiff: currentQOwnerShares,
            weightingDiff: currentQOwnerWeighting,
          });
        } else if (previousQOwner) {
          const previousQOwnerShares = previousQOwner.shares;
          const previousQOwnerWeighting = previousQOwner.percentage;
          transactions.push({
            cik,
            name,
            owner: `${owner}|${cik}`,
            date: currentQ[0]?.date || "",
            shares: 0,
            value: 0,
            status: "Sold All",
            weighting: "0%",
            sharesDiff: -previousQOwnerShares,
            weightingDiff: -previousQOwnerWeighting,
          });
        }
      }
    } else {
      for (const owner of currentQ) {
        transactions.push({
          cik: owner.cik,
          name: owner.name,
          owner: `${owner.owner}|${owner.cik}`,
          date: owner.date,
          putCall: owner.putCall,
          shares: owner.shares,
          value: owner.value,
          status: "New",
          weighting: `${owner.percentage.toFixed(2)}%`,
          sharesDiff: owner.shares,
        });
      }
    }
    all_transactions.push({
      transactions: transactions.sort(
        (a, b) => parseFloat(b.weighting) - parseFloat(a.weighting)
      ),
      date: currentQ[0]?.date || "",
    });
  }

  return { data: all_transactions };
};

/**
 * Functions for matching query against companies and investors and highlighting the match
 */

export function getQueryPattern(query: string) {
  const pattern = new RegExp(
    `(${query
      ?.trim() // Trim leading and ending whitespace
      .toLowerCase() // convert to lower case
      .split(" ") // Split on spaces for multiple commands
      .map((token) => `^${token}`) // Map over the resulting array and create Regex_
      .join("|")})`, // Join those expressions with an OR |
    "i"
  );

  return pattern;
}

// solve the subset sum problem to find the largest subset
// some credit: geeksforgeeks.org/perfect-sum-problem/
function sumSubsets(streams, n, targetSum) {
  // binary array
  let x = new Array(streams.length);
  let j = streams.length - 1;
  while (n > 0) {
    x[j] = n % 2;
    n = Math.floor(n / 2);
    j--;
  }

  // sum of this subset
  var sum = 0;
  for (let i = 0; i < streams.length; i++) {
    if (x[i] == 1) {
      sum = sum + streams[i][1];
    }
  }
  if (sum == targetSum) {
    var resultingSubset = [];
    for (let i = 0; i < streams.length; i++)
      if (x[i] == 1) resultingSubset.push(streams[i]);
    return resultingSubset;
  } else {
    return false;
  }
}

// find the subset of streams with sum targetSum
export function findLargestSubset(streams, targetSum) {
  // format revenue streams in array (from dict) for findLargestSubset algo
  const streamsArray = [];
  if (streams) {
    for (const [key, value] of Object.entries(streams)) {
      if (value != 0) {
        // do not add revenue streams with a value of 0
        streamsArray.push([key, value]);
      }
    }
  }

  let totalNumberOfSubsets = Math.pow(2, streamsArray.length);
  var largestSubset = [];

  var subsetExists = false;
  for (let i = 1; i < totalNumberOfSubsets; i++) {
    var resultingSubset = sumSubsets(streamsArray, i, targetSum);
    if (resultingSubset != false) {
      subsetExists = true;
      if (resultingSubset.length > largestSubset.length) {
        largestSubset = resultingSubset;
      }
    }
  }

  if (!subsetExists) return false;
  return largestSubset;
}

export const findIndex = (date: Dayjs, dates: string[]) => {
  if (!dates) return;
  let index: number | null = null;
  let count = 0;
  while ((index === null || index === -1) && count < 30) {
    index = dates.indexOf(
      dayjs(date).subtract(count, "day").format("YYYY-MM-DD")
    );
    count++;
  }

  return index;
};

export const generateProspectusLink = (
  url: string,
  ticker: string,
  type: string
) => {
  const data = url.split("/data/")[1].split("/");
  return `https://www.10kreader.com/company/${ticker.toLowerCase()}/${
    data[1]
  }?type=${type}&doc=${data[2]}`;
};

export const generateEPSTimeframes = (dates: [string, string]) => {
  if (!dates) return [];
  const startDate = dayjs(dates[0]);
  const endDate = dayjs(dates[1]);

  const threeYear = endDate.subtract(3, "year");
  const fiveYear = endDate.subtract(5, "year");
  const tenYear = endDate.subtract(10, "year");
  const twentyYear = endDate.subtract(20, "year");

  let timeframes = [];
  if (startDate.isBefore(twentyYear)) {
    timeframes = ["3Y", "5Y", "10Y", "20Y", "All"];
  } else if (startDate.isBefore(tenYear)) {
    timeframes = ["3Y", "5Y", "10Y", "All"];
  } else if (startDate.isBefore(fiveYear)) {
    timeframes = ["3Y", "5Y", "All"];
  } else if (startDate.isBefore(threeYear)) {
    timeframes = ["3Y", "All"];
  } else {
    timeframes = ["All"];
  }
  return timeframes;
};
