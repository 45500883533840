// SEO
import {
  SiteLinksSearchBoxJsonLd,
  SocialProfileJsonLd,
  WebPageJsonLd,
} from "next-seo";

// CSS
import "../styles/globals.css";

// Types
import type { AppProps } from "next/app";

// Notifications
import { Toaster } from "react-hot-toast";

// Auth
import { auth } from "../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserContext } from "../lib/context";

import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import PropagateLoader from "react-spinners/PropagateLoader";
import Script from "next/script";
import AppShell from "../components/AppShell";

import useWindowSize from "../lib/Hooks/useWindowSize";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db } from "../lib/firebase";

import { RecentSearch } from "../lib/types";
import LoginModal from "../components/Login/LoginModal";

const processSearches = (
  investors: string[][],
  companies: string[][],
  recentSearches: RecentSearch[]
) => {
  if (investors.length === 0 || companies.length === 0 || !recentSearches)
    return [];

  // filter with same identifier

  return recentSearches
    .sort((a, b) => b.timestamp - a.timestamp)
    .filter(
      ({ identifier }, index, array) =>
        array.findIndex(
          (i) => i.identifier.toUpperCase() === identifier.toUpperCase()
        ) === index
    )
    .map(({ heart, identifier, type }) => {
      if (type === "investor") {
        const find = investors.find((i) => i[0] === identifier);

        return {
          url: `/${type}/${identifier}`,
          name: find?.[2] || find?.[1],
          identifier,
          heart,
          type,
          coverage: "investor",
        };
      } else {
        const find = companies.find((i) => i[0] === identifier.toUpperCase());

        return {
          url: `/${type}/${identifier}`,
          name: find?.[1] || identifier,
          identifier,
          heart,
          type,
          coverage: find?.[2],
        };
      }
    });
};

function MyApp({ Component, pageProps }: AppProps) {
  const [user] = useAuthState(auth);
  const router = useRouter();
  const size = useWindowSize();
  const [loginModal, setLoginModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.pathname) {
        setLoading(true);

        if (url !== "/") {
          // Remove old local storage
          localStorage.removeItem("recentSearches");
          const recentSearches = JSON.parse(
            localStorage.getItem("simpleRecentSearches")
          );
          const split = url.split("/");
          const page = split[1];
          if (page === "investor" || page === "company" || page === "etf") {
            const identifier = split[2].split("?")[0];
            let updatedSearches = [];
            if (recentSearches) {
              const find = recentSearches.find(
                (s) => s.identifier === identifier
              );

              if (find) {
                updatedSearches = recentSearches.map((s) =>
                  s.identifier === identifier
                    ? { ...s, timestamp: new Date().getTime() }
                    : s
                );
              } else {
                updatedSearches = [
                  ...recentSearches,
                  {
                    identifier,
                    type: page,
                    timestamp: new Date().getTime(),
                    heart: false,
                  },
                ];
              }
            } else {
              updatedSearches = [
                {
                  identifier,
                  type: page,
                  timestamp: new Date().getTime(),
                  heart: false,
                },
              ];
            }
            setRecentSearches(updatedSearches);
            localStorage.setItem(
              "simpleRecentSearches",
              JSON.stringify(updatedSearches)
            );
          }
        }
      } else {
        setLoading(false);
      }
    };

    const handleComplete = (url: string) => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    // Remove event listener on cleanup
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.events, router.pathname]);

  const [investors, setInvestors] = useState<[string, string, string][]>([]);
  const [companies, setCompanies] = useState<
    [string, string, string, string, string][]
  >([]);
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>(null);

  useEffect(() => {
    const getResearchSearches = () => {
      setRecentSearches(
        JSON.parse(localStorage.getItem("simpleRecentSearches"))
      );
    };
    // Add event listener
    document.addEventListener("storage", getResearchSearches);

    // Call handler right away so state gets updated with initial selection
    getResearchSearches();

    // Remove event listener on cleanup
    return () => document.removeEventListener("storage", getResearchSearches);
  }, [setRecentSearches]);

  const processedSearches = processSearches(
    investors,
    companies,
    recentSearches
  );

  // checks to see if user record exists, otherwise uploads user details
  useEffect(() => {
    // if user is null, then return.
    if (!user) return;
    const checkUser = async () => {
      const { checkUserDetails } = await import("../lib/helper");
      checkUserDetails(user);
    };
    checkUser();
  }, [user]);

  // listens to magic link sign up
  useEffect(() => {
    if (user) return;
    const checkUserSignIn = async () => {
      const { isSignInWithEmailLink } = await import("firebase/auth");
      const { signInWithMagicLink } = await import("../lib/firebase");
      if (isSignInWithEmailLink(auth, window.location.href)) {
        signInWithMagicLink();
        router.push("/");
      }
    };
    checkUserSignIn();
  }, [user, router]);

  const isAppShell =
    router.pathname !== "/" && router.pathname !== "/privacy-policy";

  const pushContent = size.width
    ? size.width > 1023 && showSidebar
      ? true
      : false
    : false;

  const [currentTicker, setCurrentTicker] = useState<string[]>(null);

  const [userInfo] = useDocumentData(doc(db, "users", `${user?.uid}`));

  return (
    <>
      {process.env.NODE_ENV === "production" && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <Script strategy="lazyOnload" id="google-analytics">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
      `}
          </Script>
        </>
      )}
      <UserContext.Provider value={user}>
        <SocialProfileJsonLd
          type="Organization"
          name="10kreader"
          url="https://www.10kreader.com/"
          sameAs={[
            "https://www.linkedin.com/company/10kreader",
            "https://twitter.com/10kreader",
            "https://www.instagram.com/10kreader/",
          ]}
        />
        <WebPageJsonLd
          description="10kreader brings a kindle-like reading experience to the web for filings and transcripts and provides tools to easily analyze and visualize financials going back 35 years for over 40,000 stocks."
          id="https://www.10kreader.com/#website"
        />
        <SiteLinksSearchBoxJsonLd
          url="https://www.10kreader.com"
          potentialActions={[
            {
              target: "https://www.10kreader.com/screener?query",
              queryInput: "search_term_string",
            },
          ]}
        />
        <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} />
        {isAppShell && (
          <AppShell
            currentTicker={currentTicker}
            pushContent={pushContent}
            width={size.width}
            setLoginModal={setLoginModal}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            setRecentSearches={setRecentSearches}
            setCompanies={setCompanies}
            setInvestors={setInvestors}
            companies={companies}
            investors={investors}
            processedSearches={processedSearches}
            favorites={processedSearches?.filter((s) => s.heart)}
          >
            <Component
              {...pageProps}
              userInfo={userInfo}
              setCurrentTicker={setCurrentTicker}
              pushContent={pushContent}
              setCompanies={setCompanies}
              setInvestors={setInvestors}
              recentSearches={recentSearches}
              setRecentSearches={setRecentSearches}
              setLoginModal={setLoginModal}
              showSidebar={showSidebar}
              companies={companies}
              investors={investors}
              width={size.width}
              setShowSidebar={setShowSidebar}
              processedSearches={processedSearches}
              favorites={processedSearches?.filter((s) => s.heart)}
            />
          </AppShell>
        )}
        {!isAppShell && (
          <>
            {loading && (
              <div className="flex h-screen w-screen items-center justify-center">
                <PropagateLoader
                  color={"#E9795D"}
                  loading={loading}
                  size={25}
                />
              </div>
            )}
            {!loading && (
              <Component
                {...pageProps}
                width={size.width}
                setLoginModal={setLoginModal}
                processedSearches={processedSearches}
                setRecentSearches={setRecentSearches}
                companies={companies}
                investors={investors}
                setCompanies={setCompanies}
                setInvestors={setInvestors}
              />
            )}
          </>
        )}
        <Toaster
          toastOptions={{
            style: {
              background: "#3A4374",
              color: "white",
            },
          }}
        />
      </UserContext.Provider>
    </>
  );
}

export default MyApp;
