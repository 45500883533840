import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { UserContext } from "../../lib/context";

// Hooks
import { useRouter } from "next/router";

// Components
import SignUpForm from "./SignUpForm";

// Formik
import { Formik } from "formik";
import * as Yup from "yup";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please provide a valid email")
    .required("Please provide an email"),
});

export default function EmailLoginForm({
  login,
  setLogin,
}: {
  login: boolean;
  setLogin: Dispatch<SetStateAction<boolean>>;
}) {
  const user = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (user) {
      router.push("/");
    }
  }, [user, router]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const { getDoc, doc } = await import("firebase/firestore");
        const { toast } = await import("react-hot-toast");
        const { db, emailMagicLink } = await import("../../lib/firebase");

        const docSnap = await getDoc(doc(db, "usersEmail", `${values.email}`));
        if (login && !docSnap.exists()) {
          toast.error("Email does not exist, please sign up");
          setLogin(false);
        } else if (login && docSnap.exists()) {
          emailMagicLink(values.email);
        } else if (!login && docSnap.exists()) {
          toast.error("Email already exists, please log in");
          setLogin(true);
        } else if (!login && !docSnap.exists()) {
          emailMagicLink(values.email);
          window.localStorage.setItem(
            "displayName",
            `${values.firstName} ${values.lastName}`
          );
        }
        actions.setSubmitting(false);
        actions.resetForm();
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {(formik) => (
        <SignUpForm
          setLogin={setLogin}
          valid={formik.isValid}
          login={login}
          submitting={formik.isSubmitting}
        />
      )}
    </Formik>
  );
}
