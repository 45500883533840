import { useContext, SetStateAction, Dispatch, useState } from "react";
import { auth } from "../../lib/firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  FacebookAuthProvider,
  TwitterAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";
import { UserContext } from "../../lib/context";
import EmailSignUpForm from "./EmailSignUpForm";
import { Modal } from "@mantine/core";
import { FcGoogle } from "react-icons/fc";
import { BsTwitter, BsFacebook } from "react-icons/bs";
import Link from "next/link";

export default function Login({
  loginModal,
  setLoginModal,
}: {
  loginModal: boolean;
  setLoginModal: Dispatch<SetStateAction<boolean>>;
}) {
  const user = useContext(UserContext);
  const [login, setLogin] = useState(true);

  return (
    <Modal
      size="lg"
      centered
      opened={loginModal}
      onClose={() => {
        setLoginModal(false);
        setLogin(true);
      }}
      title={
        <h1 className="ml-0.5 font-title text-gray-700 md:text-xl lg:text-2xl xl:text-[28px]">
          {login ? "Log In" : "Sign Up"}
        </h1>
      }
      transition="scale"
      transitionDuration={600}
      transitionTimingFunction="ease"
    >
      {user ? (
        <SignOutButton />
      ) : (
        <SignInButton
          login={login}
          setLogin={setLogin}
          setLoginModal={setLoginModal}
        />
      )}
    </Modal>
  );
}

const SignInButton = ({
  login,
  setLogin,
  setLoginModal,
}: {
  login: boolean;
  setLogin: Dispatch<SetStateAction<boolean>>;
  setLoginModal: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className="flex w-full flex-col items-center justify-center">
    <EmailSignUpForm login={login} setLogin={setLogin} />
    {!login && (
      <div
        onClick={() => setLogin(true)}
        className="text-md my-4 text-text-dark lg:text-lg"
      >
        Already a member?{" "}
        <span className=" cursor-pointer hover:text-pallet-100 hover:underline hover:underline-offset-2">
          Log In
        </span>
      </div>
    )}
    <div className="flex w-full items-center">
      <span className="h-[1px] w-full rounded-lg bg-gray-500"></span>
      <span className="flex w-[150px] min-w-[150px] items-center justify-center text-gray-700">
        Or continue with
      </span>
      <span className="h-[1px] w-full rounded-lg bg-gray-500"></span>
    </div>
    <div className="my-4 flex">
      <FcGoogle
        size="38"
        className="mr-6 cursor-pointer"
        onClick={async () =>
          await signInWithPopup(
            auth,
            new GoogleAuthProvider(),
            browserPopupRedirectResolver
          )
        }
      />
      <BsTwitter
        size="38"
        className="mr-6 cursor-pointer text-[#1D9BF0]"
        onClick={async () =>
          await signInWithPopup(
            auth,
            new TwitterAuthProvider(),
            browserPopupRedirectResolver
          )
        }
      />
      <BsFacebook
        size="36"
        className="mt-0.5 cursor-pointer text-[#4267B2]"
        onClick={async () =>
          await signInWithPopup(
            auth,
            new FacebookAuthProvider(),
            browserPopupRedirectResolver
          )
        }
      />
    </div>
    <Link href="/privacy-policy" passHref prefetch={false}>
      <a
        className="text-md ml-2 cursor-pointer items-center justify-start text-text-dark hover:text-pallet-100 hover:underline hover:underline-offset-2"
        onClick={() => setLoginModal(false)}
      >
        Privacy Policy
      </a>
    </Link>
  </div>
);

// Sign Out Btn
export function SignOut() {
  signOut(auth);
}

function SignOutButton() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <button
        onClick={SignOut}
        className="my-20 flex rounded-md bg-pallet-200 px-5 py-3 text-white duration-200 ease-in-out hover:bg-pallet-200/90"
      >
        <span>Sign Out</span>
      </button>
    </div>
  );
}
