import Link from "next/link";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BiArrowToLeft,
  BiArrowToRight,
  BiDotsHorizontalRounded,
} from "react-icons/bi";
import useScrollPosition from "../lib/Hooks/useScrollPosition";
import { Menu, Popover, ScrollArea, Tooltip } from "@mantine/core";
import router, { useRouter } from "next/router";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BsPerson } from "react-icons/bs";
import {
  MdDateRange,
  MdLogin,
  MdLogout,
  MdOutlineBusiness,
  MdOutlineEmail,
  MdPlaylistAdd,
} from "react-icons/md";
import { signOut } from "firebase/auth";
import { auth } from "../lib/firebase";
import toast from "react-hot-toast";
import { UserContext } from "../lib/context";
import {
  AutocompleteState,
  BaseItem,
  createAutocomplete,
} from "@algolia/autocomplete-core";
import { FiSearch } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { FaChartPie, FaHandshake, FaPeopleArrows } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { TiFolderOpen } from "react-icons/ti";
import { BsCardList } from "react-icons/bs";
import { HiOutlineCalculator, HiOutlineLightBulb } from "react-icons/hi";
import { AiOutlineHeart, AiFillHeart, AiFillHome } from "react-icons/ai";
import { ProcessedSearch, RecentSearch } from "../lib/types";
import { IoMdClose } from "react-icons/io";
import { getQueryPattern } from "../lib/helper";
import { IoMdEye } from "react-icons/io";
import {
  RiExchangeDollarFill,
  RiArticleLine,
  RiExchangeFundsFill,
} from "react-icons/ri";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import Image from "next/image";

export function highlight(text: string, pattern: RegExp) {
  // Split the text based on the pattern
  const tokens = text?.split(pattern);

  // Map over the split text and test against the pattern
  return tokens?.map((token) => {
    // If the pattern matches the text, wrap the text in <mark>
    if (!pattern.test("") && pattern.test(token)) {
      return (
        <span className="text-pallet-200 underline underline-offset-2">
          {token}
        </span>
      );
    }

    // return the token back to the array
    return token;
  });
}

const investor_pics = [
  "0001336528",
  "0001404599",
  "0001649339",
  "0001745214",
  "0001773994",
  "0001601160",
  "0000860643",
  "0000860585",
  "0001166559",
  "0001559771",
  "0001358706",
  "0001376879",
  "0001766596",
  "0000949509",
  "0001115373",
  "0000923093",
  "0001135730",
  "0001179475",
  "0001037389",
  "0001536411",
  "0001035674",
  "0000820124",
  "0000814375",
  "0001103804",
  "0001217541",
  "0001167483",
  "0001484148",
  "0001165797",
  "0001389403",
  "0001510387",
  "0001532262",
  "0001592413",
  "0000883965",
  "0001039565",
  "0000868491",
  "0001697868",
  "0001766908",
  "0001106129",
  "0000915191",
  "0000850529",
  "0001159159",
  "0001020066",
  "0001063296",
  "0000934639",
  "0000783412",
  "0001112520",
  "0000846222",
  "0001766504",
  "0000767684",
  "0001588456",
  "0001135778",
  "0001858353",
  "0001015079",
  "0001484150",
  "0001061768",
  "0001067983",
  "0001709323",
  "0001079114",
  "0000200217",
  "0001657335",
  "0001671657",
  "0001027796",
  "0001070134",
  "0001105497",
  "0000948669",
  "0000898202",
  "0001279936",
  "0001027451",
  "0001759176",
  "0001106500",
  "0001720350",
  "0001029160",
  "0001553733",
  "0001641864",
  "0001142062",
  "0001549575",
  "0001569205",
  "0001656456",
  "0001105863",
  "0001427008",
  "0001647251",
  "0001034524",
  "0001036325",
  "0001375534",
  "0000096223",
  "0001581811",
  "0000807249",
  "0001133219",
  "0001099281",
  "0001040273",
  "0001603466",
  "0001631014",
  "0000859804",
  "0001056831",
  "0001096343",
  "0000947996",
  "0000936753",
  "0000905567",
  "0000921669",
  "0000807985",
  "0001519418",
  "0001314620",
  "0001720792",
  "0001061165",
  "0000732905",
  "0001448574",
  "0001697591",
  "0001350694",
  "0001631664",
];

// Clean this appshell up as well as _app.tsx immediatelly

const AppShell = ({
  children,
  setLoginModal,
  showSidebar,
  setShowSidebar,
  processedSearches,
  setRecentSearches,
  companies,
  investors,
  favorites,
  width,
  pushContent,
  setCompanies,
  setInvestors,
  currentTicker,
}: {
  children: ReactNode;
  setLoginModal: Dispatch<SetStateAction<boolean>>;
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  processedSearches: ProcessedSearch[];
  setRecentSearches: Dispatch<SetStateAction<RecentSearch[]>>;
  companies: string[][];
  investors: string[][];
  setCompanies: Dispatch<SetStateAction<string[][]>>;
  setInvestors: Dispatch<SetStateAction<string[][]>>;
  favorites: ProcessedSearch[];
  width: number;
  pushContent: boolean;
  currentTicker: string[];
}) => {
  const user = useContext(UserContext);
  const router = useRouter();
  const scroll = useScrollPosition();

  const [loading, setLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openSidefeaturesPopover, setOpenSidefeaturesPopover] = useState(false);
  const [openSidefeaturesPopoverDesktop, setOpenSidefeaturesPopoverDesktop] =
    useState(false);

  useEffect(() => {
    const handleStart = (url: string) => {
      if (
        url !== router.pathname &&
        !(
          router.pathname.split("/")[3]?.split("?")[0] === "companies" &&
          url.split("/")[3]?.split("?")[0] === "companies"
        )
      ) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    };

    const handleComplete = (url: string) => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    // Remove event listener on cleanup
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.events, router.pathname, router.asPath, setShowSidebar]);

  //   <div
  //   className={`inset-0 -z-10 max-h-[410px] ${bgBlog} ${bgScreener} ${bgEarnings} ${bgHedgeFundLetters} ${bgDCF} ${bgExecutives} ${bgSuperinvestors} bg-[#1A0447] ${
  //     showBG ? "absolute" : "hidden"
  //   } ${bgFilings} ${bgMain} ${bgFinancials} ${bgInvestors} ${bgInsiders} ${bgETF} ${bgInvestorPortfolio} ${bgInvestorCompanies} ${bgInvestorHoldings} ${bgInvestorFilings}`}
  // ></div>

  const subPage = router.asPath.split("/")[3];

  const isCompany = router.pathname.split("/")[1] === "company";
  const isETF = router.pathname.split("/")[1] === "etf";
  const isInvestor = router.pathname.split("/")[1] === "investor";
  const isOverview = router.pathname === "/company/[ticker]";
  const isFilings = router.pathname === "/company/[ticker]/filings";
  const isFiling =
    router.pathname.split("/")[1] === "company" && subPage
      ? subPage !== "filings" &&
        subPage !== "financials" &&
        subPage !== "investors" &&
        subPage !== "insiders" &&
        subPage !== "analytics" &&
        subPage !== "visualize" &&
        subPage !== "executives"
      : false;
  const isTranscript =
    router.pathname.split("/")[1] === "company" && subPage === "earnings";
  const isInvestorFiling =
    router.pathname === "/investor/[cik]/filings/[accessionNumber]";
  const isInvestorHoldings =
    router.pathname === "/investor/[cik]" ||
    router.pathname === "/investor/[cik]/[quarter]";
  const isFinancials = router.pathname === "/company/[ticker]/financials";
  const isVisualize = router.pathname === "/company/[ticker]/visualize";
  const isInvestors = router.pathname === "/company/[ticker]/investors";
  const isInsiders = router.pathname === "/company/[ticker]/insiders";
  const isDCF = router.pathname === "/company/[ticker]/financials/dcf";
  const isCompounding = router.pathname === "/compounding";
  const isWatchlist = router.pathname === "/watchlist";
  const isEarnings = router.pathname === "/earnings";
  const isSuperinvestors = router.pathname === "/investors";
  const isExecutives = router.pathname === "/company/[ticker]/executives";
  const investorPortfolio = router.pathname === "/investor/[cik]/portfolio";
  const investorCompanies = router.pathname === "/investor/[cik]/companies";
  const investorFilings = router.pathname === "/investor/[cik]/filings";
  const guruHoldings = router.pathname === "/company/[ticker]/investors";
  const isHedgeFundLetters = router.pathname === "/fund-letters";
  const favoriteCount = favorites && favorites.length > 0;
  const split = router.pathname.split("/");
  const isBlog = router.pathname === "/blog";
  const isScreener = router.pathname === "/screener";
  const isBlogPost = split[1] === "blog" && !isBlog;
  const isOtherFundLetters = router.pathname.split("/")[1] === "fund-letters";
  const showBG =
    !isFiling &&
    !isTranscript &&
    !isCompounding &&
    !isWatchlist &&
    !isBlogPost &&
    !isVisualize &&
    !isInvestorFiling;

  const bgDCF = isDCF && (favoriteCount ? "h-[250px]" : "h-[200px]");
  const bgScreener =
    isScreener &&
    (favoriteCount ? "h-[210px] xl:h-[265px]" : "h-[160px] xl:h-[215px]");

  const bgEarnings = isEarnings && (favoriteCount ? "h-[230px]" : "h-[180px]");

  const bgExecutives =
    isExecutives &&
    (favoriteCount ? "h-[320px] lg:h-[240px]" : "h-[280px] lg:h-[200px]");

  const bgFilings = isFilings && (favoriteCount ? "h-[255px]" : "h-[205px]");

  const bgMain =
    router.pathname === "/company/[ticker]" &&
    (favoriteCount ? "h-[340px]" : "h-[300px]");

  const bgFinancials =
    isFinancials &&
    (favoriteCount
      ? "h-[410px] sm:h-[330px] lg:h-[300px]"
      : "h-[360px] sm:h-[280px] lg:h-[250px]");

  const bgBlog = isBlog && (favoriteCount ? "h-[150px]" : "h-[100px]");
  const bgInvestors =
    isInvestors && (favoriteCount ? "h-[200px]" : "h-[150px]");

  const bgInsiders =
    isInsiders &&
    (favoriteCount ? "h-[360px] lg:h-[290px]" : "h-[310px] lg:h-[400px]");

  const bgETF = isETF && (favoriteCount ? "h-[220px]" : "h-[180px]");

  const bgSuperinvestors =
    isSuperinvestors && (favoriteCount ? "h-[190px]" : "h-[140px]");

  const bgHedgeFundLetters =
    isHedgeFundLetters && (favoriteCount ? "h-[230px]" : "h-[180px]");

  const bgInvestorHoldings =
    isInvestorHoldings &&
    (investor_pics.includes(router.query.cik.toString())
      ? favoriteCount
        ? "h-[400px] sm:h-[370px]"
        : "h-[350px] sm:h-[320px]"
      : favoriteCount
      ? "h-[230px] lg:h-[210px]"
      : "h-[180px] md:h-[160px]");

  const bgInvestorFilings =
    investorFilings &&
    (investor_pics.includes(router.query.cik.toString())
      ? favoriteCount
        ? "h-[315px] sm:h-[350px]"
        : "h-[265px] md:h-[300px]"
      : favoriteCount
      ? "h-[180px] lg:h-[210px]"
      : "h-[130px] md:h-[160px]");

  const bgInvestorPortfolio =
    investorPortfolio &&
    (investor_pics.includes(router.query.cik.toString())
      ? favoriteCount
        ? "h-[370px] sm:h-[350px] sm:h-[320px]"
        : "h-[320px] sm:h-[300px] sm:h-[280px]"
      : favoriteCount
      ? "h-[250px] sm:h-[200px] lg:h-[210px]"
      : "h-[200px] sm:h-[150px] md:h-[160px]");

  const bgInvestorCompanies =
    investorCompanies &&
    (favoriteCount ? "h-[260px] lg:h-[270px]" : "h-[230px] lg:h-[210px]");

  const detailedSidebar = showSidebar && width > 639;
  const responsiveSize = "24";

  const top = width
    ? width > 639
      ? 80 - scroll.y > 0
        ? 80 - scroll.y
        : 0
      : null
    : 80;

  const sidefeatures =
    !isWatchlist &&
    !isSuperinvestors &&
    !isCompounding &&
    !isEarnings &&
    !isHedgeFundLetters &&
    !isBlog &&
    !isBlogPost &&
    !isScreener &&
    !isOtherFundLetters;

  const showFavorites =
    !isFinancials &&
    !isWatchlist &&
    !isVisualize &&
    !isBlogPost &&
    !isCompounding;

  const disableTooltips = width < 1024 || detailedSidebar;

  return (
    <>
      <nav
        className={`flex h-[80px] items-center justify-between bg-pallet-500 px-4 shadow-md sm:px-0 sm:pr-8 xl:pr-12`}
      >
        <div
          className={`hidden h-full sm:flex ${
            detailedSidebar ? "min-w-[250px]" : "min-w-[75px]"
          } cursor-pointer items-center justify-center border-r-[1px] border-[#E0E0E0] text-[32px] font-semibold text-[#303F96]`}
        >
          <Link href="/" passHref prefetch={false}>
            <a>{detailedSidebar ? "10k reader" : "10k"}</a>
          </Link>
        </div>
        {detailedSidebar ? (
          <BiArrowToLeft
            size="30"
            className="hidden min-w-[25px] cursor-pointer text-[#1F0540] md:block"
            onClick={() => setShowSidebar(false)}
          />
        ) : (
          <BiArrowToRight
            size="30"
            className="hidden min-w-[25px] cursor-pointer text-[#1F0540]  md:block"
            onClick={() => setShowSidebar(true)}
          />
        )}
        <div className="mr-2 w-full sm:ml-[32px] lg:ml-[8px] xl:ml-[22px]">
          <Search
            companies={companies}
            investors={investors}
            setCompanies={setCompanies}
            setInvestors={setInvestors}
            processedSearches={processedSearches}
            setRecentSearches={setRecentSearches}
          />
        </div>
        <Menu
          gutter={16}
          placement="end"
          withArrow
          transition="scale-y"
          transitionDuration={250}
          transitionTimingFunction="ease"
          control={
            <button
              type="button"
              className="flex items-center justify-center rounded-md bg-pallet-200 px-2 py-1 text-white backdrop-blur-2xl hover:bg-pallet-200/90 sm:px-4"
            >
              <BsPerson size="24" />
            </button>
          }
          styles={{
            body: {
              backgroundColor: "#5044B9",
              border: "none",
              width: "100px",
              display: "block",
            },
            item: { color: "#fff" },
            label: { color: "#fff" },
            itemHovered: {
              color: "#ccc",
            },
            arrow: {
              backgroundColor: "#5044B9",
              border: "#5044B9",
            },
          }}
        >
          {!user ? (
            <Menu.Item icon={<MdLogin />} onClick={() => setLoginModal(true)}>
              Login
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={<MdLogout />}
              onClick={() =>
                signOut(auth).then(() =>
                  toast.success("You have been successfully been signed out!")
                )
              }
            >
              Logout
            </Menu.Item>
          )}
        </Menu>
      </nav>
      <aside
        className={`appshell-sidebar fixed bottom-0 left-0 z-50 sm:bottom-auto sm:h-screen sm:overflow-y-auto ${
          detailedSidebar ? "w-[250px]" : "w-screen sm:w-[75px]"
        } border-t-[1px] border-r-[1px] border-[#E0E0E0] bg-white text-[15px] shadow-2xl sm:border-t-[0px]`}
        style={{ top: top }}
      >
        <div
          className={`flex h-[60px] items-start justify-center px-2 sm:h-full sm:flex-col sm:justify-between  ${
            !detailedSidebar && "sm:px-0"
          }`}
          style={{
            height: `calc(100vh - ${top}px - 20px)`,
          }}
        >
          <div
            className={`w-full ${
              sidefeatures ? "flex" : "hidden"
            } h-[60px] justify-between sm:h-auto sm:flex-col ${
              detailedSidebar ? "items-start" : "items-center"
            }`}
          >
            <Link href="/" prefetch={false} passHref>
              <a className="flex min-w-[50px] cursor-pointer items-center justify-center rounded-lg py-2 px-2 hover:bg-gray-100 sm:hidden">
                <AiFillHome size={responsiveSize} />
              </a>
            </Link>
            {top === 0 && (
              <div
                className={`mt-4 hidden w-[60px] cursor-pointer items-center rounded-lg py-2 px-4 hover:bg-gray-100 md:flex`}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                {detailedSidebar ? (
                  <BiArrowToLeft
                    size={responsiveSize}
                    className="min-w-[25px] cursor-pointer text-[#1F0540]"
                  />
                ) : (
                  <BiArrowToRight
                    size={responsiveSize}
                    className="min-w-[25px] cursor-pointer text-[#1F0540]"
                    onClick={() => setShowSidebar(true)}
                  />
                )}
              </div>
            )}
            {isInvestor && (
              <>
                <TooltipLink
                  disable={disableTooltips}
                  node={
                    <Link
                      href={`/investor/${router.query.cik}`}
                      prefetch={false}
                      passHref
                    >
                      <a
                        className={`flex ${
                          detailedSidebar
                            ? "w-full"
                            : "w-[50px] justify-center sm:w-[60px]"
                        } cursor-pointer items-center rounded-lg py-2 px-2 sm:px-4 ${
                          isInvestorHoldings
                            ? "bg-gray-100"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        <FaChartPie
                          size={responsiveSize}
                          className={`${detailedSidebar && "mr-4"}`}
                        />
                        {detailedSidebar && "Holdings"}
                      </a>
                    </Link>
                  }
                  text="Holdings"
                />
                <TooltipLink
                  disable={disableTooltips}
                  node={
                    <Link
                      prefetch={false}
                      passHref
                      href={`/investor/${router.query.cik}/portfolio`}
                    >
                      <a
                        className={`flex ${
                          detailedSidebar
                            ? "w-full"
                            : "w-[50px] justify-center sm:w-[60px]"
                        } cursor-pointer items-center rounded-lg py-2 px-2 sm:px-4 ${
                          investorPortfolio
                            ? "bg-gray-100"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        <BsCardList
                          size={responsiveSize}
                          className={`${detailedSidebar && "mr-4"}`}
                        />
                        {detailedSidebar && "Portfolio"}
                      </a>
                    </Link>
                  }
                  text="Portfolio"
                />
                <TooltipLink
                  disable={disableTooltips}
                  node={
                    <Link
                      prefetch={false}
                      passHref
                      href={`/investor/${router.query.cik}/companies`}
                    >
                      <a
                        className={`flex ${
                          detailedSidebar
                            ? "w-full"
                            : "w-[50px] justify-center sm:w-[60px]"
                        } cursor-pointer items-center rounded-lg py-2 px-2 sm:px-4 ${
                          investorCompanies
                            ? "bg-gray-100"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        <GoGraph
                          size={responsiveSize}
                          className={`${detailedSidebar && "mr-4"}`}
                        />
                        {detailedSidebar && "Companies"}
                      </a>
                    </Link>
                  }
                  text="Companies"
                />
                <TooltipLink
                  disable={disableTooltips}
                  hideMobile={true}
                  node={
                    <Link
                      prefetch={false}
                      passHref
                      href={`/investor/${router.query.cik}/filings`}
                    >
                      <a
                        className={`flex cursor-pointer ${
                          detailedSidebar
                            ? "w-full"
                            : "w-[50px] justify-center sm:w-[60px]"
                        } items-center rounded-lg py-2 px-2 sm:px-4 ${
                          investorFilings || isInvestorFiling
                            ? "bg-gray-100"
                            : "hover:bg-gray-100"
                        }`}
                      >
                        <TiFolderOpen
                          size={26}
                          className={`${detailedSidebar && "mr-4"}`}
                        />
                        {detailedSidebar && "Filings"}
                      </a>
                    </Link>
                  }
                  text="Filings"
                />
              </>
            )}
            {isETF && (
              <>
                <TooltipLink
                  node={
                    <Link
                      prefetch={false}
                      passHref
                      href={`/etf/${router.query.ticker}`}
                    >
                      <a
                        className={`flex cursor-pointer ${
                          detailedSidebar
                            ? "w-full"
                            : "w-[50px] justify-center sm:w-[60px]"
                        } items-center rounded-lg py-2 px-2 sm:px-4 ${
                          isETF ? "bg-gray-100" : "hover:bg-gray-100"
                        }`}
                      >
                        <FaChartPie
                          size={responsiveSize}
                          className={`${detailedSidebar && "mr-4"}`}
                        />
                        {detailedSidebar && "ETF Holdings"}
                      </a>
                    </Link>
                  }
                  text="ETF Holdings"
                  disable={disableTooltips}
                />
              </>
            )}
            {isCompany && currentTicker && (
              <>
                {currentTicker?.[2][3] === "Y" && (
                  <TooltipLink
                    disable={disableTooltips}
                    node={
                      <Link
                        prefetch={false}
                        passHref
                        href={`/company/${router.query.ticker}`}
                      >
                        <a
                          className={`flex ${
                            detailedSidebar
                              ? "w-full"
                              : "w-[50px] justify-center sm:w-[60px]"
                          } cursor-pointer items-center rounded-lg py-2 px-1 sm:px-4 ${
                            isOverview ? "bg-gray-100" : "hover:bg-gray-100"
                          }`}
                        >
                          <MdOutlineBusiness
                            size={responsiveSize}
                            className={`${detailedSidebar && "mr-4"}`}
                          />
                          {detailedSidebar && "Business Overview"}
                        </a>
                      </Link>
                    }
                    text="Business Overview"
                  />
                )}
                {currentTicker?.[2][0] === "Y" && (
                  <TooltipLink
                    disable={disableTooltips}
                    node={
                      <Link
                        prefetch={false}
                        passHref
                        href={`/company/${router.query.ticker}/filings`}
                      >
                        <a
                          className={`flex ${
                            detailedSidebar
                              ? "w-full"
                              : "w-[50px] justify-center sm:w-[60px]"
                          } cursor-pointer items-center rounded-lg py-2 px-1 sm:px-4 ${
                            isFilings || isFiling
                              ? "bg-gray-100"
                              : "hover:bg-gray-100"
                          }`}
                        >
                          <TiFolderOpen
                            size={responsiveSize}
                            className={`${detailedSidebar && "mr-4"}`}
                          />
                          {detailedSidebar && "Filings & Transcripts"}
                        </a>
                      </Link>
                    }
                    text="Filings &amp; Transcripts"
                  />
                )}
                {currentTicker?.[2][1] === "Y" && (
                  <>
                    <TooltipLink
                      disable={disableTooltips}
                      node={
                        <Link
                          prefetch={false}
                          passHref
                          href={`/company/${router.query.ticker}/financials`}
                        >
                          <a
                            className={`flex ${
                              detailedSidebar
                                ? "w-full"
                                : "w-[50px] justify-center sm:w-[60px]"
                            } cursor-pointer items-center rounded-lg py-2 px-1 sm:px-4 ${
                              isFinancials ? "bg-gray-100" : "hover:bg-gray-100"
                            }`}
                          >
                            <GoGraph
                              size={responsiveSize}
                              className={`${detailedSidebar && "mr-4"}`}
                            />
                            {detailedSidebar && "Financials"}
                          </a>
                        </Link>
                      }
                      text="Financials"
                    />
                    <TooltipLink
                      disable={disableTooltips}
                      node={
                        <Link
                          prefetch={false}
                          passHref
                          href={`/company/${router.query.ticker}/visualize`}
                        >
                          <a
                            className={`flex  ${
                              detailedSidebar
                                ? "w-full"
                                : "w-[50px] justify-center sm:w-[60px]"
                            } cursor-pointer items-center rounded-lg py-2 px-1 text-[15px] sm:px-4 ${
                              isVisualize ? "bg-gray-100" : "hover:bg-gray-100"
                            }`}
                          >
                            <IoMdEye
                              size={responsiveSize}
                              className={`${detailedSidebar && "mr-4"}`}
                            />
                            {detailedSidebar && "Visualize Financials"}
                          </a>
                        </Link>
                      }
                      text="Visualize Financials"
                    />
                    {currentTicker?.[0].split(".")[1] !== "AX" && (
                      <TooltipLink
                        disable={disableTooltips}
                        hideMobile={true}
                        node={
                          <Link
                            prefetch={false}
                            passHref
                            href={`/company/${router.query.ticker}/financials/dcf`}
                          >
                            <a
                              className={`flex  ${
                                detailedSidebar
                                  ? "w-full"
                                  : "w-[50px] justify-center sm:w-[60px]"
                              } cursor-pointer items-center rounded-lg py-2 px-1 text-[14.5px] sm:px-4 ${
                                isDCF ? "bg-gray-100" : "hover:bg-gray-100"
                              }`}
                            >
                              <RiExchangeDollarFill
                                size={responsiveSize}
                                className={`${detailedSidebar && "mr-4"}`}
                              />
                              {detailedSidebar && "Discounted Cash Flows"}
                            </a>
                          </Link>
                        }
                        text="Discounted Cash Flows"
                      />
                    )}
                  </>
                )}
                {currentTicker?.[2][4] === "Y" && (
                  <TooltipLink
                    hideMobile={true}
                    disable={disableTooltips}
                    node={
                      <Link
                        prefetch={false}
                        href={`/company/${router.query.ticker}/investors`}
                        passHref
                      >
                        <a
                          className={`flex ${
                            detailedSidebar
                              ? "w-full"
                              : "w-[50px] justify-center sm:w-[60px]"
                          } cursor-pointer items-center rounded-lg py-2 px-1 sm:px-4 ${
                            guruHoldings ? "bg-gray-100" : "hover:bg-gray-100"
                          }`}
                        >
                          <HiOutlineLightBulb
                            size={responsiveSize}
                            className={`${detailedSidebar && "mr-4"}`}
                          />
                          {detailedSidebar && "Guru Investors"}
                        </a>
                      </Link>
                    }
                    text="Guru Investors"
                  />
                )}
                {currentTicker?.[2][0] === "Y" &&
                  currentTicker?.[2][3] === "Y" && (
                    <TooltipLink
                      hideMobile={true}
                      disable={disableTooltips}
                      node={
                        <Link
                          href={`/company/${router.query.ticker}/insiders`}
                          passHref
                          prefetch={false}
                        >
                          <a
                            className={`flex ${
                              detailedSidebar
                                ? "w-full"
                                : "w-[50px] justify-center sm:w-[60px]"
                            } cursor-pointer items-center rounded-lg py-2 px-1 sm:px-4 ${
                              isInsiders ? "bg-gray-100" : "hover:bg-gray-100"
                            }`}
                          >
                            <FaPeopleArrows
                              size={22}
                              className={`${detailedSidebar && "mr-4"}`}
                            />
                            {detailedSidebar && "Insider Trades"}
                          </a>
                        </Link>
                      }
                      text="Insider Trades"
                    />
                  )}
              </>
            )}
            <Popover
              className={`${sidefeatures ? "flex sm:hidden" : "hidden"}`}
              opened={openPopover}
              onClose={() => setOpenPopover(false)}
              position="top"
              placement="start"
              gutter={20}
              withArrow
              width={300}
              target={
                <div
                  className={`flex min-w-[40px] cursor-pointer items-center justify-center`}
                  onClick={() => setOpenPopover(!openPopover)}
                >
                  <BiDotsVerticalRounded size="30" />
                </div>
              }
            >
              {isCompany && currentTicker && (
                <>
                  {currentTicker?.[2][1] === "Y" &&
                    currentTicker?.[0].split(".")[1] !== "AX" && (
                      <Link
                        href={`/company/${router.query.ticker}/financials/dcf`}
                        passHref
                        prefetch={false}
                      >
                        <a
                          className={`flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] sm:mt-4 ${
                            isDCF ? "bg-gray-100" : "hover:bg-gray-100"
                          }`}
                          onClick={() => setOpenPopover(false)}
                        >
                          <RiExchangeDollarFill
                            size={responsiveSize}
                            className="mr-4"
                          />
                          Discounted Cash Flows
                        </a>
                      </Link>
                    )}
                  {currentTicker?.[2][4] === "Y" && (
                    <Link
                      href={`/company/${router.query.ticker}/investors`}
                      passHref
                      prefetch={false}
                    >
                      <a
                        className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 sm:mt-4 sm:px-4 ${
                          guruHoldings ? "bg-gray-100" : "hover:bg-gray-100"
                        }`}
                        onClick={() => setOpenPopover(false)}
                      >
                        <HiOutlineLightBulb
                          size={responsiveSize}
                          className="mr-4"
                        />
                        Guru Investors
                      </a>
                    </Link>
                  )}
                  {currentTicker?.[2][0] === "Y" &&
                    currentTicker?.[2][3] === "Y" && (
                      <Link
                        href={`/company/${router.query.ticker}/insiders`}
                        passHref
                        prefetch={false}
                      >
                        <a
                          className={` mt-2
                        flex w-full cursor-pointer items-center rounded-lg py-2 px-4 sm:mt-4 ${
                          isInsiders ? "bg-gray-100" : "hover:bg-gray-100"
                        }`}
                          onClick={() => setOpenPopover(false)}
                        >
                          <FaPeopleArrows size={22} className="mr-4" />
                          Insider Trades
                        </a>
                      </Link>
                    )}
                </>
              )}
              {isInvestor && (
                <Link
                  href={`/investor/${router.query.cik}/filings`}
                  passHref
                  prefetch={false}
                >
                  <a
                    className={`flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] sm:mt-4 ${
                      isInvestorFiling ? "bg-gray-100" : "hover:bg-gray-100"
                    }`}
                    onClick={() => {
                      setOpenPopover(false);
                    }}
                  >
                    <TiFolderOpen size={26} className="mr-4" />
                    Filings
                  </a>
                </Link>
              )}
              <Link href="/watchlist" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isWatchlist ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <MdPlaylistAdd size={responsiveSize} className="mr-4" />
                  My Watchlists
                </a>
              </Link>
              <Link href="/screener" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isScreener ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <TbAdjustmentsHorizontal
                    size={responsiveSize}
                    className="mr-4"
                  />
                  Stock Screener
                </a>
              </Link>
              <Link href="/investors" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isSuperinvestors ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <HiOutlineLightBulb size={responsiveSize} className="mr-4" />
                  Guru Investors
                </a>
              </Link>
              <Link href="/fund-letters" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isHedgeFundLetters || isOtherFundLetters
                      ? "bg-gray-100"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <MdOutlineEmail size={responsiveSize} className="mr-4" />
                  Hedge Fund Letters
                </a>
              </Link>
              <Link href="/compounding" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isCompounding ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <HiOutlineCalculator size={responsiveSize} className="mr-4" />
                  Compounding Calculator
                </a>
              </Link>
              <Link href="/earnings" prefetch={false} passHref>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isEarnings ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <MdDateRange size={responsiveSize} className="mr-4" />
                  Earnings Calendar
                </a>
              </Link>
              <Link href="/blog" prefetch={false} passHref>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isBlog || isBlogPost ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenPopover(false)}
                >
                  <RiArticleLine size={responsiveSize} className="mr-4" />
                  Blog
                </a>
              </Link>
            </Popover>
          </div>
          <div
            className={`mb-6 h-[60px] w-full sm:h-auto ${
              sidefeatures ? "hidden sm:flex" : "flex"
            } justify-between sm:flex-col sm:justify-start ${
              detailedSidebar ? "items-start" : "items-center"
            }`}
          >
            <Link href="/" passHref prefetch={false}>
              <a className="flex w-[40px] cursor-pointer items-center justify-center rounded-lg py-2 px-2 hover:bg-gray-100 sm:hidden sm:w-[60px]">
                <AiFillHome size={30} />
              </a>
            </Link>
            {top === 0 && (
              <div
                className={`mt-4 w-[60px] ${
                  sidefeatures ? "hidden" : "hidden md:flex"
                } cursor-pointer items-center rounded-lg py-2 px-4 hover:bg-gray-100`}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                {detailedSidebar ? (
                  <BiArrowToLeft
                    size={responsiveSize}
                    className="min-w-[25px] cursor-pointer text-[#1F0540]"
                  />
                ) : (
                  <BiArrowToRight
                    size={responsiveSize}
                    className="min-w-[25px] cursor-pointer text-[#1F0540]"
                    onClick={() => setShowSidebar(true)}
                  />
                )}
              </div>
            )}
            <TooltipLink
              disable={disableTooltips}
              node={
                <Link href="/watchlist" passHref prefetch={false}>
                  <a
                    className={`flex cursor-pointer ${
                      detailedSidebar
                        ? "w-full"
                        : "w-[40px] justify-center sm:w-[60px]"
                    } items-center rounded-lg py-2 px-2 sm:px-4 ${
                      isWatchlist ? "bg-gray-100" : "hover:bg-gray-100"
                    }`}
                  >
                    <MdPlaylistAdd
                      size={responsiveSize}
                      className={`${detailedSidebar && "mr-4"}`}
                    />
                    {detailedSidebar && "My Watchlists"}
                  </a>
                </Link>
              }
              text="My Watchlists"
            />
            <TooltipLink
              disable={disableTooltips}
              node={
                <Link href="/screener" passHref prefetch={false}>
                  <a
                    className={`flex cursor-pointer ${
                      detailedSidebar
                        ? "w-full"
                        : "w-[40px] justify-center sm:w-[60px]"
                    } items-center rounded-lg py-2 px-2 ${
                      isScreener ? "bg-gray-100" : "hover:bg-gray-100"
                    } sm:px-4`}
                  >
                    <TbAdjustmentsHorizontal
                      size={responsiveSize}
                      className={`${detailedSidebar && "mr-4"}`}
                    />
                    {detailedSidebar && "Stock Screener"}
                  </a>
                </Link>
              }
              text="Stock Screener"
            />
            <TooltipLink
              disable={disableTooltips}
              node={
                <Link href="/investors" passHref prefetch={false}>
                  <a
                    className={`flex cursor-pointer ${
                      detailedSidebar
                        ? "w-full"
                        : "w-[40px] justify-center sm:w-[60px]"
                    } items-center rounded-lg py-2 px-2 sm:px-4 ${
                      isSuperinvestors ? "bg-gray-100" : "hover:bg-gray-100"
                    }`}
                  >
                    <HiOutlineLightBulb
                      size={responsiveSize}
                      className={`${detailedSidebar && "mr-4"}`}
                    />
                    {detailedSidebar && "Guru Investors"}
                  </a>
                </Link>
              }
              text="Guru Investors"
            />
            <TooltipLink
              disable={disableTooltips}
              node={
                <Link href="/fund-letters" passHref prefetch={false}>
                  <a
                    className={`flex cursor-pointer ${
                      detailedSidebar
                        ? "w-full"
                        : "w-[40px] justify-center sm:w-[60px]"
                    } items-center rounded-lg py-2 px-2 sm:px-4 ${
                      isHedgeFundLetters || isOtherFundLetters
                        ? "bg-gray-100"
                        : "hover:bg-gray-100"
                    }`}
                  >
                    <MdOutlineEmail
                      size={responsiveSize}
                      className={`${detailedSidebar && "mr-4"}`}
                    />
                    {detailedSidebar && "Hedge Fund Letters"}
                  </a>
                </Link>
              }
              text="Hedge Fund Letters"
            />
            <TooltipLink
              hideMobile
              disable={disableTooltips}
              node={
                <Link href="/earnings" passHref prefetch={false}>
                  <a
                    className={`flex cursor-pointer ${
                      detailedSidebar
                        ? "w-full"
                        : "w-[40px] justify-center sm:w-[60px]"
                    } items-center rounded-lg py-2 px-2 sm:px-4 ${
                      isEarnings ? "bg-gray-100" : "hover:bg-gray-100"
                    }`}
                  >
                    <MdDateRange
                      size={responsiveSize}
                      className={`${detailedSidebar && "mr-4"}`}
                    />
                    {detailedSidebar && "Earnings Calendar"}
                  </a>
                </Link>
              }
              text="Earnings Calendar"
            />
            <Popover
              className={`hidden ${showSidebar && "pl-2"} sm:block`}
              opened={openSidefeaturesPopoverDesktop}
              onClose={() => setOpenSidefeaturesPopoverDesktop(false)}
              position="bottom"
              placement="start"
              gutter={0}
              withArrow
              width={300}
              target={
                <div
                  className={`flex min-w-[40px] cursor-pointer items-center justify-center`}
                  onClick={() =>
                    setOpenSidefeaturesPopoverDesktop(
                      !openSidefeaturesPopoverDesktop
                    )
                  }
                >
                  <BiDotsHorizontalRounded size="30" />
                </div>
              }
            >
              <Link href="/compounding" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isCompounding ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenSidefeaturesPopoverDesktop(false)}
                >
                  <HiOutlineCalculator size={responsiveSize} className="mr-4" />
                  Compounding Calculator
                </a>
              </Link>
              <Link href="/blog" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isBlog || isBlogPost ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenSidefeaturesPopoverDesktop(false)}
                >
                  <RiArticleLine size={responsiveSize} className="mr-4" />
                  Blog
                </a>
              </Link>
            </Popover>
            <Popover
              className="flex sm:hidden"
              opened={openSidefeaturesPopover}
              onClose={() => setOpenSidefeaturesPopover(false)}
              position="top"
              placement="start"
              gutter={20}
              withArrow
              width={300}
              target={
                <div
                  className={`flex min-w-[40px] cursor-pointer items-center justify-center`}
                  onClick={() =>
                    setOpenSidefeaturesPopover(!openSidefeaturesPopover)
                  }
                >
                  <BiDotsVerticalRounded size="30" />
                </div>
              }
            >
              <Link href="/compounding" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isCompounding ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenSidefeaturesPopover(false)}
                >
                  <HiOutlineCalculator size={responsiveSize} className="mr-4" />
                  Compounding Calculator
                </a>
              </Link>
              <Link href="/earnings" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isEarnings ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenSidefeaturesPopover(false)}
                >
                  <MdDateRange size={responsiveSize} className="mr-4" />
                  Earnings Calendar
                </a>
              </Link>
              <Link href="/blog" passHref prefetch={false}>
                <a
                  className={`mt-2 flex w-full cursor-pointer items-center rounded-lg py-2 px-4 text-[15px] ${
                    isBlog || isBlogPost ? "bg-gray-100" : "hover:bg-gray-100"
                  }`}
                  onClick={() => setOpenSidefeaturesPopover(false)}
                >
                  <RiArticleLine size={responsiveSize} className="mr-4" />
                  Blog
                </a>
              </Link>
            </Popover>
          </div>
        </div>
      </aside>
      {loading && (
        <div
          className={`flex items-center justify-center ${
            pushContent ? "sm:ml-[250px]" : "sm:ml-[75px]"
          }`}
          style={{
            height: `calc(92vh - 60px)`,
          }}
        >
          <PropagateLoader color={"#E9795D"} loading={loading} size={25} />
        </div>
      )}
      {!loading && (
        <main
          className={`relative z-0 bg-pallet-500 ${
            isInvestorFiling || isFiling ? "mb-28 sm:mb-0" : "mb-28 sm:mb-10"
          } ${pushContent ? "sm:ml-[250px]" : "sm:ml-[75px]"} ${
            showFavorites && "px-4 sm:px-8 xl:px-12"
          }`}
          onClick={() => width < 1024 && setShowSidebar(false)}
        >
          {favoriteCount && showFavorites && (
            <FavoriteBar
              padding={true}
              favorites={favorites}
              setRecentSearches={setRecentSearches}
              bgColor={true}
            />
          )}
          <div
            className={`inset-0 -z-10 max-h-[410px] ${bgBlog} ${bgScreener} ${bgEarnings} ${bgHedgeFundLetters} ${bgDCF} ${bgExecutives} ${bgSuperinvestors} bg-[#1A0447] ${
              showBG ? "absolute" : "hidden"
            } ${bgFilings} ${bgMain} ${bgFinancials} ${bgInvestors} ${bgInsiders} ${bgETF} ${bgInvestorPortfolio} ${bgInvestorCompanies} ${bgInvestorHoldings} ${bgInvestorFilings}`}
          ></div>

          {children}
        </main>
      )}
    </>
  );
};

export const FavoriteBar = ({
  favorites,
  separate,
  setRecentSearches,
  bgColor,
  padding,
}: {
  favorites: ProcessedSearch[];
  separate?: boolean;
  setRecentSearches: Dispatch<
    SetStateAction<
      {
        identifier: string;
        timestamp: number;
        heart: boolean;
        type: string;
      }[]
    >
  >;
  bgColor: boolean;
  padding?: boolean;
}) => {
  const router = useRouter();

  return (
    <ScrollArea
      styles={{
        root: {
          height: 50,
        },
      }}
      scrollbarSize={5}
      scrollHideDelay={500}
    >
      <div
        className={`tabs flex h-[4vh] min-h-[50px] ${
          bgColor && "bg-[#1A0447]"
        } py-2 ${separate ? "" : "rounded-md"} ${
          !padding && "px-4 sm:px-8 xl:px-12"
        }`}
      >
        {favorites.map(({ url, type, identifier, name }) => {
          const isEqual =
            (router.asPath.split("/")[1] === "investor"
              ? `/investor/${router.query.cik}`
              : router.asPath) === url;

          return (
            <div
              key={identifier}
              className={`mr-2 flex max-w-[200px] items-center justify-between rounded-md px-2 py-2 ${
                isEqual ? "bg-[#E9795D]" : "bg-white"
              }`}
            >
              <button
                className={`text-md mr-2 w-full cursor-pointer overflow-hidden whitespace-nowrap font-[500]  ${
                  !isEqual
                    ? "text-text-dark hover:text-pallet-100"
                    : "text-white"
                }`}
                onMouseDown={() => router.push(url)}
              >
                {type === "investor" ? name : identifier.toUpperCase()}
              </button>
              <div
                className={`h-4 w-4 cursor-pointer rounded-full  ${
                  isEqual
                    ? "text-white hover:bg-gray-100 hover:text-text-dark"
                    : "hover:bg-gray-200"
                }`}
              >
                <IoMdClose
                  onClick={() => {
                    const update = JSON.parse(
                      localStorage.getItem("simpleRecentSearches")
                    ).map((s) =>
                      s.identifier === identifier ? { ...s, heart: false } : s
                    );
                    localStorage.setItem(
                      "simpleRecentSearches",
                      JSON.stringify(update)
                    );
                    //@ts-ignore
                    setRecentSearches(update);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
};

export const HeartSearch = ({
  heart,
  identifier,
  setRecentSearches,
}: {
  heart: boolean;
  identifier: string;
  setRecentSearches: Dispatch<SetStateAction<RecentSearch[]>>;
}) => (
  <>
    {heart ? (
      <AiFillHeart
        size="18"
        className="cursor-pointer"
        onClick={() => {
          const update = JSON.parse(
            localStorage.getItem("simpleRecentSearches")
          ).map((s) =>
            s.identifier === identifier ? { ...s, heart: false } : s
          );
          localStorage.setItem("simpleRecentSearches", JSON.stringify(update));
          setRecentSearches(update);
        }}
      />
    ) : (
      <AiOutlineHeart
        size="18"
        className="cursor-pointer"
        onClick={() => {
          // check if identifier already in the list
          const searches = JSON.parse(
            localStorage.getItem("simpleRecentSearches")
          );
          if (searches && searches.length > 0) {
            const find = searches.find((s) => s.identifier === identifier);
            if (find) {
              const update = searches.map((s) =>
                s.identifier === identifier ? { ...s, heart: true } : s
              );
              localStorage.setItem(
                "simpleRecentSearches",
                JSON.stringify(update)
              );
              setRecentSearches(update);
            } else {
              const update = [
                ...searches,
                {
                  identifier,
                  type: "investor",
                  heart: true,
                  timestamp: new Date().getTime(),
                },
              ];
              localStorage.setItem(
                "simpleRecentSearches",
                JSON.stringify(update)
              );
              setRecentSearches(update);
            }
          } else {
            const update = [
              {
                identifier,
                type: "investor",
                heart: true,
                timestamp: new Date().getTime(),
              },
            ];
            localStorage.setItem(
              "simpleRecentSearches",
              JSON.stringify(update)
            );
            setRecentSearches(update);
          }
        }}
      />
    )}
  </>
);

const TooltipLink = ({
  disable,
  text,
  node,
  hideMobile,
}: {
  text: string;
  node: React.ReactNode;
  disable: boolean;
  hideMobile?: boolean;
}) => (
  <Tooltip
    label={
      <span className="font-karla text-[15.5px] tracking-wide">{text}</span>
    }
    styles={{
      body: {
        backgroundColor: "#665BC2",
        marginLeft: "6px",
        borderRadius: "6px !important",
      },
    }}
    disabled={disable}
    className={`w-full items-center justify-center sm:mt-2.5 ${
      hideMobile ? "hidden sm:flex" : "flex"
    }`}
    transition="pop"
    transitionDuration={200}
    transitionTimingFunction="ease"
    position="top"
    placement="end"
  >
    {node}
  </Tooltip>
);

export const Search = ({
  landing,
  investors,
  companies,
  advanced,
  processedSearches,
  setRecentSearches,
  setCompanies,
  setInvestors,
  shadow,
  bottom,
  disabled,
  setOpenSearchModal,
  modal,
}: {
  landing?: boolean;
  companies?: string[][];
  investors?: string[][];
  setCompanies?: Dispatch<SetStateAction<string[][]>>;
  setInvestors?: Dispatch<SetStateAction<string[][]>>;
  advanced?: boolean;
  processedSearches: ProcessedSearch[];
  setRecentSearches: Dispatch<SetStateAction<RecentSearch[]>>;
  shadow?: boolean;
  bottom?: boolean;
  disabled?: boolean;
  setOpenSearchModal?: Dispatch<SetStateAction<boolean>>;
  modal?: boolean;
}) => {
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<BaseItem>
    //@ts-ignore
  >({});
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (
      (autocompleteState.query && autocompleteState.query.length > 0) ||
      focus
    ) {
      const updateSearchSources = async () => {
        if (!companies || companies.length === 0) {
          setCompanies(
            (await import("../public/jsons/search/companies_search.json"))
              .default
          );
        }
        if (!investors || investors.length === 0) {
          setInvestors(
            (await import("../public/jsons/search/investors.json")).default
          );
        }
      };
      updateSearchSources();
    }
  }, [
    autocompleteState.query,
    setCompanies,
    setInvestors,
    companies,
    investors,
    focus,
  ]);

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        defaultActiveItemId: 0,
        placeholder: "Search for companies, investors, and ETFs",
        navigator: {
          navigate({ itemUrl }) {
            router.push(itemUrl);
          },
        },
        async getSources() {
          let sourceCompanies = companies || [];
          let sourceInvestors = investors || [];
          return [
            {
              sourceId: "recentSearches",
              getItems({ query }) {
                const pattern = getQueryPattern(query);

                return processedSearches
                  .filter(
                    (s) =>
                      s.identifier
                        ?.toLowerCase()
                        .includes(query.trim().toLowerCase()) ||
                      s.name?.toLowerCase().includes(query.trim().toLowerCase())
                  )
                  .slice(0, 3)
                  .map((s) => ({
                    ...s,
                    highlightedName: highlight(s.name, pattern),
                    highlightedIdentifier: highlight(
                      s.identifier.toUpperCase(),
                      pattern
                    ),
                  }));
              },
              getItemUrl({ item }) {
                if (item.type === "investor") {
                  return `${item.url}`;
                } else if (item.type === "company" || item.type === "etf") {
                  const ticker = item.identifier.toString().toLowerCase();
                  const coverage = item.coverage as string;
                  const filings = coverage[0] === "Y";
                  const financials = coverage[1] === "Y";
                  const etf = coverage[2] === "Y";
                  const profile = coverage[3] === "Y";
                  if (profile && !etf) {
                    return `/company/${ticker}`;
                  } else if (etf) {
                    return `/etf/${ticker}`;
                  } else if (filings) {
                    return `/company/${ticker}/filings`;
                  } else if (financials) {
                    return `/company/${ticker}/financials`;
                  }
                }
              },
              onSelect() {
                autocomplete.setIsOpen(false);
                autocomplete.setQuery("");
                // @ts-ignore
                document.querySelector(".search")?.blur();
              },
            },
            {
              sourceId: "companies",
              getItems({ query }) {
                const pattern = getQueryPattern(query);

                return sourceCompanies
                  .filter(
                    (c) =>
                      c[0]?.match(pattern) ||
                      c[1]?.toLowerCase().includes(query.trim().toLowerCase())
                  )
                  .map((company) => {
                    const ticker = company[0];
                    const name = company[1];
                    const coverage = company[2];

                    return {
                      tickerMatch: ticker.match(pattern)?.length > 0,
                      ticker,
                      name,
                      coverage,
                      filings: coverage[0] === "Y",
                      country: company[3],
                      exchange: company[4],
                      highlightTicker: highlight(ticker, pattern),
                      highlightName: highlight(name, pattern),
                    };
                  })
                  .sort((a, b) => Number(b.filings) - Number(a.filings))
                  .sort((a, b) => Number(b.tickerMatch) - Number(a.tickerMatch))
                  .slice(0, 5);
              },
              onSelect() {
                autocomplete.setIsOpen(false);
                autocomplete.setQuery("");
                // @ts-ignore
                document.querySelector(".search")?.blur();
              },
              getItemUrl({ item }) {
                const ticker = item.ticker.toString().toLowerCase();
                const coverage = item.coverage as string;
                const filings = coverage[0] === "Y";
                const financials = coverage[1] === "Y";
                const etf = coverage[2] === "Y";
                const profile = coverage[3] === "Y";

                if (profile && !etf) {
                  return `/company/${ticker}`;
                } else if (etf) {
                  return `/etf/${ticker}`;
                } else if (filings) {
                  return `/company/${ticker}/filings`;
                } else if (financials) {
                  return `/company/${ticker}/financials`;
                }
              },
            },
            {
              sourceId: "investors",
              getItems({ query }) {
                const pattern = getQueryPattern(query);

                return sourceInvestors
                  .filter(
                    (i) =>
                      i[0]
                        ?.toLowerCase()
                        .includes(query.trim().toLowerCase()) ||
                      i[1]
                        ?.toLowerCase()
                        .includes(query.trim().toLowerCase()) ||
                      i[2]?.toLowerCase().includes(query.trim().toLowerCase())
                  )
                  .slice(0, 5)
                  .map((i) => ({
                    cik: i[0],
                    name: i[1],
                    owner: i[2],
                    highlightCIK: highlight(i[0], pattern),
                    highlightName: highlight(i[1], pattern),
                    highlightOwner: highlight(i[2], pattern),
                  }));
              },
              getItemUrl({ item }) {
                return `/investor/${item.cik}`;
              },
              onSelect() {
                autocomplete.setIsOpen(false);
                autocomplete.setQuery("");
                // @ts-ignore
                document.querySelector(".search")?.blur();
              },
            },
          ];
        },
      }),
    [investors, processedSearches, companies]
  );

  const inputRef = useRef(null);
  const formRef = useRef(null);
  const panelRef = useRef(null);

  const { getEnvironmentProps } = autocomplete;

  useEffect(() => {
    if (!(formRef.current && panelRef.current && inputRef.current)) {
      return;
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      panelElement: panelRef.current,
      inputElement: inputRef.current,
    });

    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchmove", onTouchMove);

    return () => {
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
    };
  }, [getEnvironmentProps, formRef, panelRef, inputRef]);

  useEffect(() => {
    if (autocompleteState.query) {
      autocomplete.setQuery(autocompleteState.query);
      autocomplete.setIsOpen(true);
      autocomplete.refresh();
    }
  }, [autocompleteState.query, autocomplete]);

  return (
    <div
      {...autocomplete.getRootProps({})}
      className="relative w-full font-karla"
    >
      {/* @ts-ignore */}
      <form
        ref={formRef}
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        className={`relative w-full rounded-md bg-white ${
          landing ? "py-7" : "py-6"
        } ${
          autocompleteState.isOpen &&
          focus &&
          "rounded-b-none border-b-[1px] border-gray-200"
        }`}
        onClick={() => disabled && setOpenSearchModal(true)}
      >
        {/* @ts-ignore */}
        <input
          ref={inputRef}
          // @ts-ignore
          {...autocomplete.getInputProps({})}
          className={`search text-md absolute ${
            shadow && "custom-shadow"
          } inset-0 h-full w-full rounded-md ${
            autocompleteState.isOpen && focus && "rounded-b-none"
          } bg-transparent pl-16 pr-10 text-text-dark placeholder-[#C7C8DD] caret-[#7E8CF7] outline-none md:text-lg`}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          disabled={disabled}
        />
        <div className="absolute top-0 left-5 flex h-full items-center justify-center">
          <FiSearch size="28" className="text-[#7E8CF7]" />
        </div>
        <div className="absolute top-0 right-5 flex h-full items-center justify-center">
          {autocompleteState.query && autocompleteState.query.length > 0 && (
            <div className="flex h-full items-center justify-center">
              <div
                className="cursor-pointer rounded-full p-0.5 text-[#777BAB] duration-100 ease-in hover:text-[#494D77]"
                onClick={() => {
                  autocomplete.setQuery("");
                  autocomplete.setIsOpen(false);
                }}
              >
                <IoIosClose size="26" />
              </div>
            </div>
          )}
        </div>
      </form>
      {autocompleteState.isOpen && focus && (
        // @ts-ignore
        <div
          ref={panelRef}
          {...autocomplete.getPanelProps({})}
          className={`absolute z-50  ${
            bottom ? "h-[320px] xl:h-[400px]" : "h-[500px]"
          } w-full overflow-y-auto rounded-b-lg bg-white shadow-md`}
        >
          <ScrollArea scrollbarSize={5} scrollHideDelay={500}>
            <div>
              {autocompleteState.collections.map((collection) => {
                const { source, items } = collection;

                return (
                  <div
                    key={source.sourceId}
                    className="mx-auto bg-white text-lg"
                  >
                    {items.length > 0 && (
                      <>
                        <div className="text-md mx-8 my-3 flex flex-col text-text-dark md:text-lg">
                          <span className="flex w-full items-center justify-center font-semibold sm:justify-start">
                            {source.sourceId === "companies" && "Companies"}
                            {source.sourceId === "investors" && "Investors"}
                            {source.sourceId === "recentSearches" &&
                              "Recent Searches"}
                          </span>
                        </div>
                        <div className="my-2 h-[1px] w-full bg-gray-200"></div>
                        <ul
                          className="aa-List"
                          {...autocomplete.getListProps()}
                        >
                          {items.map((item, index) => {
                            if (source.sourceId === "recentSearches") {
                              let link: string;

                              if (item.type === "investor") {
                                link = `${item.url}`;
                              } else if (
                                item.type === "company" ||
                                item.type === "etf"
                              ) {
                                const ticker = item.identifier
                                  .toString()
                                  .toLowerCase();
                                const coverage = item.coverage as string;
                                const filings = coverage[0] === "Y";
                                const financials = coverage[1] === "Y";
                                const etf = coverage[2] === "Y";
                                const profile = coverage[3] === "Y";
                                if (profile && !etf) {
                                  link = `/company/${ticker}`;
                                } else if (etf) {
                                  link = `/etf/${ticker}`;
                                } else if (filings) {
                                  link = `/company/${ticker}/filings`;
                                } else if (financials) {
                                  link = `/company/${ticker}/financials`;
                                }
                              }

                              return (
                                <li
                                  key={source.sourceId + link + index}
                                  className={`flex cursor-pointer items-center text-gray-900 hover:bg-[#F0F3F5] ${
                                    autocompleteState.activeItemId ===
                                      item.__autocomplete_id && "bg-[#F0F3F5]"
                                  } ${
                                    autocompleteState.status === "stalled" &&
                                    "opacity-50"
                                  } relative mx-1 rounded-md md:mx-0 md:rounded-none`}
                                >
                                  <Link href={link} passHref prefetch={false}>
                                    <a
                                      onClick={() => {
                                        autocomplete.setIsOpen(false);
                                        autocomplete.setQuery("");
                                        document
                                          .querySelector(".search")
                                          // @ts-ignore
                                          ?.blur();
                                      }}
                                      className="flex w-full cursor-pointer items-center"
                                    >
                                      <div className="hidden w-1/6 min-w-[150px] items-center justify-start sm:flex md:w-1/5">
                                        {autocompleteState.activeItemId ===
                                          item.__autocomplete_id && (
                                          <div className="absolute top-0 left-0 hidden h-full w-[3.5px] rounded-r-lg bg-[#5C43F5] md:block"></div>
                                        )}
                                        <span className="py-2 px-8 uppercase">
                                          {item.highlightedIdentifier}
                                        </span>
                                      </div>
                                      <div className="relative flex w-full flex-col rounded-md px-4 py-2 pr-12 md:w-4/5 md:rounded-none md:py-0 md:pl-8">
                                        <span>{item.highlightedName}</span>
                                        {(item.type === "company" ||
                                          item.type === "etf") && (
                                          <span className="text-md text-gray-500 sm:hidden md:text-lg">
                                            {item.highlightedIdentifier}
                                          </span>
                                        )}
                                      </div>
                                    </a>
                                  </Link>
                                  <div
                                    className={`absolute flex items-center justify-center ${
                                      landing
                                        ? "right-[18px] sm:right-[34px] xl:right-[18px]"
                                        : "right-[18px] sm:right-[34px]"
                                    }`}
                                  >
                                    <HeartSearch
                                      heart={item.heart as boolean}
                                      identifier={item.identifier as string}
                                      setRecentSearches={setRecentSearches}
                                    />
                                  </div>
                                </li>
                              );
                            }
                            if (source.sourceId === "companies") {
                              let link: string = "";
                              const ticker = item.ticker
                                .toString()
                                .toLowerCase();
                              const coverage = item.coverage as string | null;
                              const filings = coverage && coverage[0] === "Y";
                              const financials =
                                coverage && coverage[1] === "Y";
                              const etf = coverage && coverage[2] === "Y";
                              const profile = coverage && coverage[3] === "Y";

                              if (profile && !etf) {
                                link = `/company/${ticker}`;
                              } else if (etf) {
                                link = `/etf/${ticker}`;
                              } else if (filings) {
                                link = `/company/${ticker}/filings`;
                              } else if (financials) {
                                link = `/company/${ticker}/financials`;
                              }

                              return (
                                <li
                                  key={source.sourceId + link + index}
                                  className={`flex cursor-pointer items-center text-gray-900 hover:bg-[#F0F3F5] ${
                                    autocompleteState.activeItemId ===
                                      item.__autocomplete_id && "bg-[#F0F3F5]"
                                  } ${
                                    autocompleteState.status === "stalled" &&
                                    "opacity-50"
                                  } relative mx-1 rounded-md md:mx-0 md:rounded-none`}
                                >
                                  <Link href={link} passHref prefetch={false}>
                                    <a
                                      onClick={() => {
                                        autocomplete.setIsOpen(false);
                                        autocomplete.setQuery("");
                                        document
                                          .querySelector(".search")
                                          // @ts-ignore
                                          ?.blur();
                                      }}
                                      className="flex w-full cursor-pointer items-center"
                                    >
                                      <div className="hidden w-1/6 min-w-[150px] items-center justify-start sm:flex md:w-1/5">
                                        {autocompleteState.activeItemId ===
                                          item.__autocomplete_id && (
                                          <div className="absolute top-0 left-0 hidden h-full w-[3.5px] rounded-r-lg bg-[#5C43F5] md:block"></div>
                                        )}
                                        <span className="py-2 px-8 uppercase">
                                          {item.highlightTicker}
                                        </span>
                                      </div>
                                      <div className="relative flex w-full flex-col rounded-md px-4 py-2 sm:pr-28 md:w-4/5 md:rounded-none md:py-0 md:pl-8">
                                        <span>{item.highlightName}</span>
                                        <span className="text-md text-gray-500 sm:hidden md:text-lg">
                                          {item.highlightTicker}
                                        </span>
                                      </div>
                                    </a>
                                  </Link>
                                  {item.country && (
                                    <div
                                      className={`absolute ${
                                        landing
                                          ? "right-[16px] sm:right-[32px] xl:right-[16px]"
                                          : "right-[16px] sm:right-[32px]"
                                      } hidden items-center justify-end rounded-full sm:flex`}
                                    >
                                      <span className="text-[15px] font-[500] text-gray-500">
                                        {item.exchange || ""}
                                      </span>
                                      <div className="relative ml-2 h-[22px] w-[22px] rounded-full shadow-md">
                                        <Image
                                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.country.toString()}.svg`}
                                          layout="fill"
                                          objectFit="cover"
                                          objectPosition="center"
                                          className="rounded-full"
                                          alt={`${item.country.toString()} Flag`}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </li>
                              );
                            }
                            if (source.sourceId === "investors") {
                              return (
                                <Link
                                  href={`/investor/${item.cik}`}
                                  passHref
                                  key={item.cik as string}
                                  prefetch={false}
                                >
                                  <a
                                    onClick={() => {
                                      autocomplete.setIsOpen(false);
                                      autocomplete.setQuery("");
                                      // @ts-ignore
                                      document.querySelector(".search")?.blur();
                                    }}
                                  >
                                    <li
                                      className={`flex cursor-pointer items-center text-gray-900 hover:bg-[#F0F3F5] ${
                                        autocompleteState.activeItemId ===
                                          item.__autocomplete_id &&
                                        "bg-[#F0F3F5]"
                                      } ${
                                        autocompleteState.status ===
                                          "stalled" && "opacity-50"
                                      } relative mx-1 rounded-md md:mx-0 md:rounded-none`}
                                    >
                                      <div
                                        className={`hidden w-1/6 min-w-[150px] items-center justify-start md:w-1/5 ${
                                          item.owner ? "py-4" : "py-2"
                                        } px-8 sm:flex`}
                                      >
                                        {autocompleteState.activeItemId ===
                                          item.__autocomplete_id && (
                                          <div className="absolute top-0 left-0 hidden h-full w-[3.5px] rounded-r-lg bg-[#5C43F5] md:block"></div>
                                        )}
                                        <span className="">{item.cik}</span>
                                      </div>

                                      <div className="relative flex w-full flex-col rounded-md px-4 py-2 pr-12 md:w-3/4 md:rounded-none md:py-0 md:pl-8">
                                        <span>{item.highlightName}</span>
                                        {item.owner && (
                                          <span className="text-md text-gray-500 md:text-lg">
                                            {item.highlightOwner}
                                          </span>
                                        )}
                                      </div>
                                    </li>
                                  </a>
                                </Link>
                              );
                            }
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                );
              })}
              <div
                className={`mx-8 my-2 py-3 text-center sm:text-left sm:text-[17px] ${
                  advanced && "hidden"
                }`}
              >
                <Link
                  href={`/screener?query=${autocompleteState.query}`}
                  prefetch={false}
                >
                  <a
                    className="text-md cursor-pointer text-text-dark hover:text-pallet-100 hover:underline hover:underline-offset-4"
                    onClick={() => {
                      autocomplete.setIsOpen(false);
                      autocomplete.setQuery("");
                    }}
                  >
                    Can&apos;t find what you are looking for? Go to advanced
                    search.
                  </a>
                </Link>
              </div>
            </div>
          </ScrollArea>
        </div>
      )}
      {landing && !bottom && !modal && (
        <div className="my-3 flex items-center justify-center text-[13px] sm:text-[16.5px] xl:justify-start xs:text-[16px]">
          <span className="mr-2 font-[500] text-gray-500">
            Popular Searches:
          </span>
          <PopularSearches name="Apple" ticker="AAPL" />
          <PopularSearches name="Tesla" ticker="TSLA" hideOnMobile />
          <PopularSearches name="Amazon" ticker="AMZN" hideOnMobile />
          <PopularSearches name="Alphabet" ticker="GOOGL" />
          <PopularSearches
            name="Warren Buffett"
            ticker="0001067983"
            isInvestor
          />
        </div>
      )}
    </div>
  );
};

const PopularSearches = ({
  name,
  ticker,
  isInvestor,
  hideOnMobile,
}: {
  name: string;
  ticker: string;
  isInvestor?: boolean;
  hideOnMobile?: boolean;
}) => (
  <>
    <Link
      passHref
      prefetch={false}
      href={
        "/" + (isInvestor ? "investor/" : "company/") + ticker.toLowerCase()
      }
    >
      <a
        className={`text-pallet-200 hover:underline hover:underline-offset-2 ${
          hideOnMobile && "hidden sm:block"
        }`}
      >
        {name}
      </a>
    </Link>
    {!isInvestor && (
      <div
        className={`mx-1 h-[20px] w-[0.5px] rounded-md bg-gray-500 sm:mx-2 ${
          hideOnMobile && "hidden sm:block"
        }`}
      ></div>
    )}
  </>
);

export default AppShell;
