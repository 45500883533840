import {
  FastField,
  ErrorMessage,
  useFormikContext,
  FormikValues,
  Form,
} from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import { IoIosWarning } from "react-icons/io";

const SignUpForm = ({
  valid,
  submitting,
  login,
  setLogin,
}: {
  valid: boolean;
  submitting: boolean;
  login: boolean;
  setLogin: Dispatch<SetStateAction<boolean>>;
}) => {
  const { errors } = useFormikContext<FormikValues>();
  return (
    <Form className="w-full">
      <EmailField errors={Object.keys(errors).length > 0} />
      {!login && (
        <div className="mb-4 flex flex-col sm:flex-row">
          <FirstNameField />
          <LastNameField />
        </div>
      )}
      <div className="w-full">
        <button
          type="submit"
          disabled={!valid || submitting}
          className="w-full cursor-pointer rounded-md bg-pallet-200 py-2 text-white duration-150 ease-in-out hover:bg-pallet-200/90"
        >
          {login ? "Log In" : "Sign Up"}
        </button>
        {login && (
          <button
            onClick={() => setLogin(false)}
            type="button"
            className="my-4 w-full cursor-pointer rounded-md bg-10kreader-orange py-2 text-white duration-150 ease-in-out hover:bg-10kreader-orange/90"
          >
            Create a New Account
          </button>
        )}
      </div>
    </Form>
  );
};

const EmailField = ({ errors }: { errors: boolean }) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <div
        className={`relative ${
          !errors && "mb-4"
        } flex h-full items-center rounded-md bg-pallet-500 ${
          focus && "ring-[1px] ring-[#9a97cf]"
        }`}
      >
        <label
          htmlFor="email"
          className="flex min-w-[120px] items-center justify-start pl-8 text-sm text-gray-700 sm:min-w-[105px] md:text-lg lg:text-[19px]"
        >
          Email <span>*</span>
        </label>
        <FastField
          className="login-input h-full w-full py-4 outline-none focus:outline-none"
          type="text"
          name="email"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
      {errors && (
        <div className="mt-2 mb-4">
          <ErrorMessage name="email">
            {(error) => (
              <span className="flex items-center text-[15px] text-red-600">
                <IoIosWarning className="mr-2" />
                <span>{error}</span>
              </span>
            )}
          </ErrorMessage>
        </div>
      )}
    </>
  );
};

const FirstNameField = () => {
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={`relative mb-4 flex h-full items-center rounded-md bg-pallet-500 sm:mr-4 ${
        focus && "ring-[1px] ring-[#9a97cf]"
      }`}
    >
      <label
        htmlFor="email"
        className="flex min-w-[120px] items-center justify-start pl-8 text-sm text-gray-700 md:min-w-[145px] md:text-lg"
      >
        First Name <span>*</span>
      </label>
      <FastField
        className="login-input h-full w-full bg-transparent py-4 outline-none autofill:rounded-md focus:outline-none"
        type="text"
        name="firstName"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};

const LastNameField = () => {
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={`relative flex h-full items-center rounded-md bg-pallet-500 ${
        focus && "ring-[1px] ring-[#9a97cf]"
      }`}
    >
      <label
        htmlFor="email"
        className="flex min-w-[120px] items-center justify-start pl-8 text-sm text-gray-700 md:min-w-[145px] md:text-lg"
      >
        Last Name <span>*</span>
      </label>
      <FastField
        className="login-input h-full w-full bg-transparent py-4 outline-none autofill:rounded-md focus:outline-none"
        type="text"
        name="lastName"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};

export default SignUpForm;
