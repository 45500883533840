import { getApp, initializeApp } from "firebase/app";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  updateProfile,
  User,
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import toast from "react-hot-toast";

// config for the firebase project
const firebaseConfig = {
  apiKey: "AIzaSyAQ4rxPo-SLpUeRWqFsBEtFiHoOGldkycE",
  authDomain: "search-company-filings.firebaseapp.com",
  projectId: "search-company-filings",
  storageBucket: "search-company-filings.appspot.com",
  messagingSenderId: "548253090674",
  appId: "1:548253090674:web:f05cc66a5b6aa84e3bd3eb",
  measurementId: "G-VZKEF8Q3F2",
};

const Firebase = initializeApp(firebaseConfig);

export const app = getApp();
export const auth = initializeAuth(app, {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
  ],
});
export const db = getFirestore();

// function to email the user a magic link
export const emailMagicLink = async (email: string) => {
  return await sendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}`, // returns to origin
    handleCodeInApp: true,
  })
    .then(() => {
      toast.success("A link was sent to your email!");
      window.localStorage.setItem("emailForSignIn", email);
    })
    .catch((error) => {
      console.error(error);
    });
};

// function to sign in the current user from their magic link
export const signInWithMagicLink = () => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.

    let email = window.localStorage.getItem("emailForSignIn");
    let displayName = window.localStorage.getItem("displayName");

    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again.
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    // @ts-ignore: email is stored in the localStorage already, and error is handled if it were null
    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        // Upload profile with displayName from local storage
        updateProfile(auth.currentUser as User, {
          displayName: displayName,
        });

        // Delete email and displayName from local storage
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("emailForSignIn");
        toast.success("You have successfully been signed in!");
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
};
export default Firebase;
